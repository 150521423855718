import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ReloadListAction} from "mindsets-js-sdk";

import {DeleteProjectAction} from "../../../actions/project_actions";
import InvalidateProjectButton from "./InvalidateProjectButton";

const Row = function ({item, row_number}) {
    const dispatch = useDispatch();

    let organization_name = '';
    if (item.type === 'district' && item.district) {
        organization_name = item.district.name;
    } else if (item.type === 'school' && item.school) {
        organization_name = item.school.name;
    }

    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td>
                <h5>{item.name}</h5>
                <div>{organization_name}</div>
            </td>
            <td>{item.type}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <Link to={'/projects/' + item.id} className="button">Manage</Link>
                    <Link to={'/lesson_schedules?column~0=project_id&expression~0=eq&value~0=' + item.id}
                          className='button'>
                        Schedules
                    </Link>
                    <Link to={'/dashboards?column~0=project_id&expression~0=eq&value~0=' + item.id}
                          className='button'>
                        Dashboards
                    </Link>
                    <button
                        className='button red'
                        onClick={() => {
                            if (confirm('Are you sure you want to delete this Project?')) {
                                dispatch(DeleteProjectAction(item.id)).then(() => dispatch(ReloadListAction(true, 'projects')));
                            }
                        }}
                    >
                        <i className='fas fa-trash'/>
                    </button>
                    <InvalidateProjectButton id={item.id}/>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        district: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        school: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
